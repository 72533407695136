
import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DataService } from "src/app/Services/dataservice.service";
import { HttpClient, HttpEvent, HttpResponse } from "@angular/common/http";
import { HttpEventType } from "@angular/common/http";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { link } from "fs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { GetContentService } from "src/app/Services/getcontent.service";

@Component({
  selector: "app-cover-page",
  templateUrl: "./cover-page.component.html",
  styleUrls: ["./cover-page.component.scss"],
})
export class CoverPageComponent implements OnInit {
  fileData: File = null;
  images: any = [];
  allfiles: any = [];
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flows: string;
  showNotificationCount: boolean;
  notificationCount: number;
  maxtitlelenght = 80;
  maxdesclenght = 500;
  currenttitlelenght = 0;
  currentdesclenght = 0;
  enableButton: boolean;
  title: string;
  @Input() description: string = '';
  imageURI: any;
  videoURI: any;
  coverPageURI: any;
  coverPosterPageURI: any;
  flowId: any;
  uniqueId: any;
  type: any;
  showImage: boolean;
  showVideo: boolean;
  fileName: string;
  posterURI: any;
  price: any;
  isPaid: boolean;
  noResultReturned: boolean;
  duration: number;
  Url: string;
  documentName: any;
  filename: string;
  supportingDocumentDocuments: any[];
  supportingDocumentHyperLinks: any[];
  isAddSupportingDocument: boolean;
  linkName: any;
  hyperLink: any;
  sffilename: any;
  sffilelink: any;
  tabIndex: any;
  FlowCount: any;
  noApprovedFlows: any;
  totalSupportingDocuments: number;
  rowKey: any;
  partitionKey: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userserviceService: UserserviceService,
    private dataService: DataService,
    private toastManager: ToastrService, 
    private getcontentService: GetContentService,
    private flowServiceService: FlowServiceService
  ) {
    this.title = "";
    this.description = "";
    this.documentName = "";
    this.enableButton = true;
    this.duration = 0;
    this.supportingDocumentHyperLinks = [];
    this.supportingDocumentDocuments = [];
    this.totalSupportingDocuments = 0;
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
 
    //
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    this.noResultReturned = false;
    //
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });

    var flowCoverPage = JSON.parse(
      localStorage.getItem("flowCoverPageDetails")
    );
 
    if (flowCoverPage != null) {
      this.isImageOrVideo(flowCoverPage.coverImage);
      this.coverPageURI = flowCoverPage.coverImage;
      this.price = flowCoverPage.price;
      this.coverPosterPageURI = flowCoverPage.coverPosterPageURI;
      this.title = flowCoverPage.title;
      this.duration = flowCoverPage.duration;
      this.description = flowCoverPage.description;
      this.flowId = flowCoverPage.flowId;
      this.uniqueId = flowCoverPage.uniqueId;
      this.type = flowCoverPage.type;
      this.isPaid = flowCoverPage.isPaid;
      this.rowKey = flowCoverPage.rowKey;
      this.partitionKey = flowCoverPage.partitionKey;
    } 
    var supportingDocumentDocuments = JSON.parse(
      localStorage.getItem("SupportingDocumentDocuments")
    );

    var supportingDocumentHyperLinks = JSON.parse(
      localStorage.getItem("SupportingDocumentHyperLinksRequired")
    );

    if (supportingDocumentDocuments != null) {
      this.supportingDocumentDocuments = supportingDocumentDocuments;
    }

    if (supportingDocumentHyperLinks != null) {
      this.supportingDocumentHyperLinks = supportingDocumentHyperLinks;
    }
 
    this.getProfileUser();
    this.titleChanged();
  }

  isImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showImage = true;
      this.showVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showVideo = true;
      this.showImage = false;
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  submit() {
    this.noResultReturned = true;
    //
    var flowCoverPage = {
      coverImage: this.coverPageURI,
      description: this.description,
      coverPosterPageURI: this.coverPosterPageURI,
      title: this.title,
      flowId: this.flowId,
      uniqueId: this.uniqueId,
      type: this.type,
      duration: this.duration,
      fileName: this.fileName,
      price: this.price,
      isPaid: this.isPaid,
      rowKey: this.rowKey,
      partitionKey: this.partitionKey
    };

    localStorage.setItem("flowCoverPageDetails", JSON.stringify(flowCoverPage));
 
    this.noResultReturned = false;
    this.router.navigate(["/create-flow"]);
  }

  checkValue() {
    if (!this.isPaid) {
      this.price = null;
    }
  }

  getProfileUser(): void { 
    if (this.userData != null) {
      this.noResultReturned = true;
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser().pipe(
          catchError((error) => {
            // Handle the error here
            console.error('Error occurred while fetching profile user:', error);
            // You can return a default value or re-throw the error if needed
            return of(null); // Returning null as a default value
            // Or you can re-throw the error
            // return throwError(error);
          })
        )
        .subscribe((data) => {
          this.noResultReturned = false;
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            // 
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
  removePicture() {
    this.profilePicture = null;
  }
  setPosterImage(fileData) {
    let url = fileData.replace("." + this.getExtention(fileData), ".jpg");
    this.posterURI = url;
  }

  getExtention(fileData) {
    return fileData.slice(((fileData.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.supportingDocumentDocuments,
      event.previousIndex,
      event.currentIndex
    );
  }
  close() {
    localStorage.setItem(
      "supportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    this.router.navigate(["/create-flow"]);
  }

  editLink(index, link) { 
    this.linkName = link.linkName;
    this.hyperLink = link.linkUrl;
    localStorage.setItem("editsupportingDocumentHyperLinksIndex", index);
  }

  addLink() { 
    var editsupportingDocumentHyperLinksIndex = localStorage.getItem(
      "editsupportingDocumentHyperLinksIndex"
    );
    if (editsupportingDocumentHyperLinksIndex != null) { 
      this.supportingDocumentHyperLinks.splice(
        parseInt(editsupportingDocumentHyperLinksIndex),
        1
      );
      var slink = {
        linkName: this.linkName,
        linkUrl: this.hyperLink,
      };
      this.supportingDocumentHyperLinks
        .splice(parseInt(editsupportingDocumentHyperLinksIndex), 0, slink);
    }else{
      this.supportingDocumentHyperLinks.push({
        linkName: this.linkName,
        linkUrl: this.hyperLink,
      });
    }
    this.linkName = "";
    this.hyperLink = "";
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );
    localStorage.removeItem("editsupportingDocumentHyperLinksIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  addSupportingDocument() {
    if (
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length >=
      10
    ) {
      return;
    }

    var editSupportingDocumentRequiredIndex = localStorage.getItem(
      "editSupportingDocumentRequiredIndex"
    );

    if (editSupportingDocumentRequiredIndex != null) { 
      this.supportingDocumentDocuments.splice(
        parseInt(editSupportingDocumentRequiredIndex),
        1
      ); var document = {
        documentName: this.documentName,
        documentUrl: this.sffilelink,
      };

      this.supportingDocumentDocuments
        .splice(parseInt(editSupportingDocumentRequiredIndex), 0, document);
    }else{
      this.supportingDocumentDocuments.push({
        documentName: this.documentName,
        documentUrl: this.sffilelink,
      });
    }
    this.documentName = "";
    this.sffilename = "";
    this.sffilelink = "";

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    localStorage.removeItem("editSupportingDocumentRequiredIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  editSupportingDocument(index, document) { 
    this.documentName = document.documentName;
    this.sffilelink = document.documentUrl;
    localStorage.setItem("editSupportingDocumentRequiredIndex", index);
  }

  tabChanged(tabChangedEvent) { }

  remove(index) {
    this.supportingDocumentDocuments.splice(index, 1);

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  removeLink(index) {
    this.supportingDocumentHyperLinks.splice(index, 1);
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  uploadSupportingDocuments(event, source) { 
    if (event.target.files) {
      this.noResultReturned = true;
      //
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);

      // this.isStepCoverPageURISet = true;
      this.isAddSupportingDocument = true;
      if (
        this.fileData.name.includes("pdf") ||
        this.fileData.name.includes("PDF")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadDocumentsUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any; 
            this.showImage = true;
            this.showVideo = false;
            this.noResultReturned = false;
            //
            if (mediaUploadStatus != null) {
              this.sffilename = mediaUploadStatus.fileName;
              this.sffilelink =
                environment.documentsStorageBaseUrl +
                mediaUploadStatus.fileName;
              this.openLink(this.sffilelink);
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }

  openLink(url) {
    url = "https://" + url; 
    window.open(url);
  }

  selectFiles(event, source) { 
    if (event.target.files) {
      this.noResultReturned = true;
      //
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);

      // this.isStepCoverPageURISet = true;
      this.isAddSupportingDocument = true;
      if (
        this.fileData.name.includes("JPEG") ||
        this.fileData.name.includes("jpeg") ||
        this.fileData.name.includes("png") ||
        this.fileData.name.includes("jpg") ||
        this.fileData.name.includes("PNG") ||
        this.fileData.name.includes("JFIF") ||
        this.fileData.name.includes("jfif") ||
        this.fileData.name.includes("TIFF") ||
        this.fileData.name.includes("tiff") ||
        this.fileData.name.includes("TIF") ||
        this.fileData.name.includes("tif") ||
        this.fileData.name.includes("JPG") ||
        this.fileData.name.includes("pdf") ||
        this.fileData.name.includes("PDF")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadImageUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any; 
            this.showImage = true;
            this.showVideo = false;
            this.noResultReturned = false;
            
            if (mediaUploadStatus != null) {
              if (source == "cp") {
                this.fileName = this.fileData.name;
                this.coverPageURI =
                  environment.imageStorageBaseUrl +
                  mediaUploadStatus.fileName;
              } else if (source == "sd") {
                this.sffilename = mediaUploadStatus.fileName;
              }
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      } else if (
        this.fileData.name.includes("MP4") ||
        this.fileData.name.includes("mp4") ||
        this.fileData.name.includes("MOV") ||
        this.fileData.name.includes("mov") ||
        this.fileData.name.includes("WMV") ||
        this.fileData.name.includes("wmv") ||
        this.fileData.name.includes("FLV") ||
        this.fileData.name.includes("flv") ||
        this.fileData.name.includes("AVCHD") ||
        this.fileData.name.includes("avchd") ||
        this.fileData.name.includes("WEBM") ||
        this.fileData.name.includes("webm") ||
        this.fileData.name.includes("MKV") ||
        this.fileData.name.includes("mkv") ||
        this.fileData.name.includes("VOB") ||
        this.fileData.name.includes("vob") ||
        this.fileData.name.includes("OGV") ||
        this.fileData.name.includes("ogv") ||
        this.fileData.name.includes("GIFY") ||
        this.fileData.name.includes("gifv") ||
        this.fileData.name.includes("M4P") ||
        this.fileData.name.includes("m4p") ||
        this.fileData.name.includes("M4V") ||
        this.fileData.name.includes("m4v") ||
        this.fileData.name.includes("3GP") ||
        this.fileData.name.includes("3gp") ||
        this.fileData.name.includes("3G2") ||
        this.fileData.name.includes("3g2") ||
        this.fileData.name.includes("AMV") ||
        this.fileData.name.includes("amv") ||
        this.fileData.name.includes("NSV") ||
        this.fileData.name.includes("nsv") ||
        this.fileData.name.includes("OGG") ||
        this.fileData.name.includes("ogg") ||
        this.fileData.name.includes("FLV") ||
        this.fileData.name.includes("flv") ||
        this.fileData.name.includes("F4V") ||
        this.fileData.name.includes("f4v") ||
        this.fileData.name.includes("F4P") ||
        this.fileData.name.includes("f4p") ||
        this.fileData.name.includes("F4A") ||
        this.fileData.name.includes("f4a") ||
        this.fileData.name.includes("F4B") ||
        this.fileData.name.includes("f4b")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadVideoUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showVideo = true;
            this.showImage = false;
            this.noResultReturned = false;
            if (mediaUploadStatus != null) {
              if (source == "cp") {
                this.fileName = this.fileData.name;
                this.coverPageURI =
                  environment.videoStorageBaseUrl +
                  mediaUploadStatus.fileName;
                this.coverPosterPageURI =
                  environment.imageStorageBaseUrl +
                  mediaUploadStatus.posterImageName;
              } else if (source == "sd") {
                this.sffilename = mediaUploadStatus.fileName;
              }
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }

  titleChanged() {
    this.title = this.title.trim();

    if (this.title.length == this.maxtitlelenght) {
      this.title = this.title.substring(0, this.title.length);
    }

    if (this.currenttitlelenght <= this.maxtitlelenght)
      this.currenttitlelenght = this.title.length;

    this.setIsDisable();
  }

  setIsDisable() {
    if (
      this.currenttitlelenght > 0 &&
      this.currentdesclenght > 0 &&
      (this.imageURI != null || this.videoURI != null)
    ) {
      this.enableButton = false;
    } else {
      this.enableButton = true;
    }
  }

  descChanged() {
    this.description = this.description.trim();
    if (this.description.length == this.maxdesclenght) {
      this.description = this.description.substring(0, this.description.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght)
      this.currentdesclenght = this.description.length;

    this.setIsDisable();
  }

  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;

  GoToOriginalText() {
    this.description = this.originalContentTitleText;
  }

  async rephraseDescriptionText(input: string) {

    if (input == undefined || input == "") {
      this.toastManager.warning(
        "The Text To Rephrase is required."
      ); 
      return;
    }

    if (!this.isRephrased) {
      this.originalContentTitleText = input;
    }

    this.isRephrased = true;
    this.isRephraseInProgress = true;
    console.log(input);
    var aiModel = {
      textToRephrase: input
    };
    (await this.getcontentService
      .getContentFromAI(aiModel))
    .subscribe((aiResponse) => {
      if(aiResponse){
        console.log(aiResponse);
        console.log(aiResponse[0].completions);
        this.description = aiResponse[0].completions;
        this.isRephraseInProgress = false;
      }
    });
    this.isRephraseInProgress = false;
    
  }
  originalTitleText!: string;
  repharaseButtonTitleText: string = "Rephrase text with AI";
  isTitleRephrased: boolean = false;
  isTitleRephraseInProgress: boolean = false;

  GoToOriginalTitleText() {
    this.title = this.originalTitleText;
  }

  async rephraseTitleText(input: string) {

    if (input == undefined || input == "") {
      this.toastManager.warning(
        "The Text To Rephrase is required."
      ); 
      return;
    }

    if (!this.isTitleRephrased) {
      this.originalTitleText = input;
    }

    this.isTitleRephrased = true;
    this.isTitleRephraseInProgress = true;
    console.log(input);
    var aiModel = {
      textToRephrase: input
    };
    (await this.getcontentService
      .getContentFromAI(aiModel))
    .subscribe((aiResponse) => {
      if(aiResponse){
        console.log(aiResponse);
        console.log(aiResponse[0].completions);
        this.title = aiResponse[0].completions;
        this.isTitleRephraseInProgress = false;
      }
    });
    this.isTitleRephraseInProgress = false; 
  }
}
