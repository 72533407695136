import { BundleService } from "src/app/Services/bundleservice.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-report-bundle",
  templateUrl: "./report-bundle.component.html",
  styleUrls: ["./report-bundle.component.scss"],
})
export class ReportBundleComponent implements OnInit {
  title: string;
  reasons: any;
  reasonsToSubmit: any;
  sexual: boolean;
  graphic: boolean;
  hateful: boolean;
  harmful: boolean;
  rating: boolean;
  illegal: boolean;
  copycat: boolean;
  other: boolean;
  reason: string;
  flowData: any;
  userData: any;
  imageSource: any;
  description: any;
  nextCount: number;
  itemsRequired: number;
  stepCount: number;
  progressPercentage: number;
  progressBy: number;
  dataSource = [];
  dataSourceSteps = [];
  showimage: boolean;
  bundleData: any;
  flowCoverImageUrl: any;

  constructor(
    private bundleService: BundleService,
    private router: Router,
    private dataService: DataService,
    
    private toastr: ToastrService,
    public formbuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReportBundleComponent>
  ) {
    this.reasonsToSubmit = [];
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.bundleData = JSON.parse(localStorage.getItem("bundleData"));
    this.title = this.bundleData.title;
    this.flowCoverImageUrl = "url(" + '"' + this.bundleData.url + '"' + ")";
  }

  submit() { 
    if (this.reason == "sexual") {
      this.reasonsToSubmit.push("Sexual content");
    }
    if (this.reason == "graphic") {
      this.reasonsToSubmit.push("Graphic violence");
    }
    if (this.reason == "hateful") {
      this.reasonsToSubmit.push("Hateful or abusive content");
    }
    if (this.reason == "rating") {
      this.reasonsToSubmit.push("Improper content rating");
    }
    if (this.reason == "illegal") {
      this.reasonsToSubmit.push("Illegal prescription or other drug");
    }
    if (this.reason == "copycat") {
      this.reasonsToSubmit.push("Copycat or impresonation");
    }
    if (this.reason == "other") {
      this.reasonsToSubmit.push("Other objection");
    }

    var bundleModel = {
      FlowId: this.bundleData.rowKey,
      Reasons: this.reasonsToSubmit,
      //ReportedByUserEmail: value
    };

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.bundleService
        .reportBundle(bundleModel, this.userData.emailAddress)
        .subscribe((data) => {
          

          if (data.MessageText == "publish bundle completed successfully.") { 
            this.toastr.success("Bundle reported successfully");
            localStorage.setItem("notificationflowId", null);
            this.router.navigate(["/grid"]);
            this.dialogRef.close();
          }
        });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
