import { BundleService } from "src/app/Services/bundleservice.service";
import { DialogExampleComponent } from "./../../dialog-example/dialog-example.component";
import { UserserviceService } from "./../../Services/userservice.service";
import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { FlowServiceService } from "src/app/Services/flow-service.service";

import { Router, NavigationExtras } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
// import * as firebase from "firebase";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
import { MatDialog } from "@angular/material/dialog";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";
import { Location } from "@angular/common";
import { BundledialogComponent } from "../bundledialog/bundledialog.component";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { UserProfileQrCodeComponent } from "../user-profile-qr-code/user-profile-qr-code.component";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime } from "rxjs/operators";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { FlowQueryService } from "src/app/shared/flowquery.helper";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  animal: "panda" | "unicorn" | "lion";

  userDisplayName: any;
  userDisplayEmail: any;
  carouselOptions: any;
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;

  isthereAnyFlows: boolean;

  flows: any = [];
  savedFlows: any = [];
  userData: any;
  approvedFlows: any = [];
  draftFlows: any = [];
  profileUser: any = [];
  purchasedFlows: any = [];
  bundles: any = [];

  followerCount: number;
  followingCount: number;

  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  showDefaultImage: boolean;
  isMobileView: boolean;
  categories: any;
  showNotificationCount: boolean;
  notificationCount: number;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any = [];
  notificationscount: number;
  account_circle: any;

  ShowAboutYou: boolean;
  ShowEmail: boolean;
  ShowName: boolean;
  ShowWebsite: boolean;
  ShowPhone: boolean;

  FlowCount: number;
  Bundlecount: number;
  Draftcount: number;
  Savedcount: number;
  Purchasedcount: number;
  FollowingCount: number;
  FollowersCount: number;

  tabIndex: any;
  _steps: any = [];

  tempFlows: any = [];
  istempFlows: any = [];
  itistempFlows: any = [];
  getNotificationUrl: string;
  getFollowersUrl: string;
  itemsRequired: any[];
  savedFlowslength: number;
  draftFlowslength: number;
  approvedFlowslength: number;

  infiniteScrollCount: number;
  isthereAnySavedFlows: boolean;
  isthereAnyDraftFlows: boolean;
  isthereAnyPurchasedFlows: boolean;

  noApprovedFlows: boolean;
  noDraftFlows: boolean;
  noSavedFlows: boolean;
  noPurchasedFlows: boolean;
  noBundles: boolean;

  noResultReturned: boolean;

  selector: string = ".example-container";
  userEmail: any;
  tempBundles: any = [];
  CategoryName: string;
  isItBundle: boolean;
  isthereAnyBundles: boolean;
  flowPurchased: boolean;

  activeTabIndex: number;
  add_circle_outlinebgColor: string;
  add_circle_outlineColor: string;
  add_circle_outlineSize: string;

  constructor(
    private flowServiceService: FlowServiceService,
    private dataService: DataService,

    private router: Router,
    private userserviceService: UserserviceService,
    private eventEmitterService: EventEmitterService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    public location: Location,
    private bundleService: BundleService,
    private titleandmetaserviceService: TitleandmetaserviceService,
    private flowQueryService: FlowQueryService
  ) {
    this.activeTabIndex = 0;
    this.titleandmetaserviceService.setTitle("User Profile");
    this.noflow = false;
    this.savedFlowslength = 0;
    this.approvedFlowslength = 0;
    this.draftFlowslength = 0;
    this.infiniteScrollCount = 100;
    this.getNotificationUrl = "v1/Notification/GetUserNotifications";
    this.tabIndex = localStorage.getItem("draftTabIndex");
  }

  openImgDialog() {
    localStorage.removeItem("profilePicture");
    this.dialog.open(ImageDialogComponent, {
      data: {
        animal: "panda",
      },
    });
  }

  gotoUserQrCode() {
    localStorage.setItem("userprofileqrCode", "user-profile");
    const dialogRef = this.dialog.open(UserProfileQrCodeComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  ngOnInit() {
    this.isthereAnyFlows = true;
    this.isthereAnyDraftFlows = true;
    this.isthereAnySavedFlows = true;
    this.flowServiceService.setScrollTop();
    const width = window.innerWidth || document.body.clientWidth;
    this.eventEmitterService.setHomePageIconColorEvent("user");
    this.eventEmitterService.setUserProfileCreateIconClickedEvent("profile");
    localStorage.setItem("approvedPageIndex", "0");
    localStorage.setItem("draftPageIndex", "0");
    localStorage.setItem("savePageIndex", "0");
    localStorage.setItem("purchasePageIndex", "0");

    if (width <= 1024) {
      this.isMobileView = true;
    }
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userEmail = this.userData.emailAddress;
    }
    this.flows = [];
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.tempBundles = [];
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.uname = this.userData.userName;
      this.name = this.userData.firstName;
      this.followerCount = 0;
      this.followingCount = 0;


      this.getProfileUser();
      this.getApprovedFlows();
      if (this.tabIndex == 1) {
        this.callBundleFlowService(0);
      }

      this.scrollSubject.pipe(debounceTime(2000))
        .subscribe({
          next: event => {
            this.performScroll(event);
          },
          error: error => {
            console.error('Error in scroll subscription:', error);
          },
        });
    } else {
      this.isItBundle = false;
      this.router.navigate(["login"]);
    }

    this.eventEmitterService.setNotificationCompleted =
      this.eventEmitterService.setNotificationFunction.subscribe(
        (notificationModelArray: any) => {
          this.notificationsarray = [];
          this.showNotificationCount = true;
          const notifications = JSON.parse(notificationModelArray);
          notifications.forEach((element) => {
            this.notificationsarray.push(element.notification);
          });
          this.notificationscount = this.notificationsarray.length;
          this.ref.detectChanges();
        }
      );
  }

  flowClicked(flow) {
    localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.setItem("notificationflowId", null);
    localStorage.setItem("navigateTo", "user-profile");
    if (flow.type == "Bundle") {
      this.isItBundle = true;
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else {
      this.isItBundle = false;
      this.router.navigate(["/view/" + flow.rowKey]);
    }
  }

  draftflowClicked(flow) {
    var flowCoverPage = {
      coverImage: flow.url,
      rowKey: flow.rowKey,
      description: flow.description,
      title: flow.title,
      type: flow.type,
      uniqueId: flow.uniqueId,
      partitionKey: flow.partitionKey
    };

    console.log(flowCoverPage);

    this.noResultReturned = true;
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    var email = "";

    if (this.userData != null) {
      email = this.userData.emailAddress;
    }


    var flowQueryModel = new FlowQueryModel({
      FlowId: flow.rowKey,
      userEmail: email
    });

    this.flowServiceService.getFlowById(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      if (data.flows != null) {
        localStorage.removeItem("flow");
        localStorage.setItem(
          "flowCoverPageDetails",
          JSON.stringify(flowCoverPage)
        );
        localStorage.setItem(
          "stepsCoverPageDetails",
          JSON.stringify(data.flows.steps)
        );
        localStorage.setItem(
          "ItemsRequired",
          JSON.stringify(data.flows.components)
        );
        localStorage.setItem("draftWord", "Draft Flow");
        this.router.navigate(["/create-flow"]);
      } else {
      }
    });
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }

  follower() {
    this.router.navigate(["/followers"]);
  }

  following() {
    this.router.navigate(["/followers"]);
  }

  avatarClicked(notification: any, source: any) {
    if (source == "flow clicked") {
      if (notification.contentType == "Flow") {
        localStorage.setItem("notificationflowId", notification.rowKey);
        localStorage.setItem("flow", null);
        this.router.navigate(["/view/" + notification.rowKey]);
      } else if (notification.contentType == "Bundle") {
        this.router.navigate(["/viewbundle/" + notification.rowKey]);
      }
    } else if (source == "profile clicked") {
      if (this.userData.emailAddress === notification.notifierUserEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }

      this.dataService.flowByCategorieschangeMessage(notification);
    }
  }

  search() {
    this.router.navigate(["/home"]);
  }

  getPurchasedFlows(arg0: boolean): void {
    //

    this.noPurchasedFlows = true;
    this.isthereAnyPurchasedFlows = true;
    this.noResultReturned = true;
    this.purchasedFlows = [];
    this.tempFlows = [];
    localStorage.removeItem("purchasePageIndex");
    localStorage.setItem("purchasePageIndex", "0");
    this.callPurchasedFlowService("0");
  }

  getApprovedFlows(): void {
    //

    this.noApprovedFlows = true;
    this.isthereAnyFlows = true;
    this.noResultReturned = true;
    this.approvedFlows = [];
    this.tempFlows = [];
    localStorage.removeItem("approvedPageIndex");
    localStorage.setItem("approvedPageIndex", "0");
    this.callServiceForApprovedFlows("0");
  }

  getDraftFlows(isCalledFromNgInit) {
    this.isthereAnyDraftFlows = true;
    this.noDraftFlows = true;
    //
    this.noResultReturned = true;
    this.draftFlows = [];
    this.itistempFlows = [];
    this.tabIndex = localStorage.getItem("draftTabIndex");
    if (this.tabIndex == "1") {
      localStorage.removeItem("draftTabIndex");
    }
    localStorage.removeItem("draftPageIndex");
    localStorage.setItem("draftPageIndex", "0");
    this.callDraftFlowService("0");
  }

  getSavedFlows(isCalledFromNgInit): void {
    //   this.noResultReturned = true;
    //
    this.noSavedFlows = true;
    this.savedFlows = [];
    this.istempFlows = [];
    this.noResultReturned = true;
    localStorage.removeItem("savePageIndex");
    localStorage.setItem("savePageIndex", "0");
    this.callSaveFlowService("0");
  }

  getBundles(isCalledFromNgInit) {
    this.isthereAnyBundles = true;
    this.noBundles = true;
    //
    this.bundles = [];
    this.tempBundles = [];
    this.noResultReturned = true;
    localStorage.removeItem("bundlePageIndex");
    localStorage.setItem("bundlePageIndex", "0");
    this.callBundleFlowService(0);
  }

  ngOnDestroy() {
    this.scrollSubject.unsubscribe();
  }

  performScroll(event) {
    if (this.activeTabIndex == 0) {
      // var pageIndex = localStorage.getItem("approvedPageIndex");
      // pageIndex = (parseInt(pageIndex) + 1).toString();
      // localStorage.setItem("approvedPageIndex", pageIndex);
      // this.callServiceForApprovedFlows(pageIndex);
      this.handleTabScroll(0, "approvedPageIndex");
      var pageIndex = localStorage.getItem("approvedPageIndex")
      this.callServiceForApprovedFlows(pageIndex);
    }

    if (this.activeTabIndex == 1) {
      // var pageIndex = localStorage.getItem("bundlePageIndex");
      // pageIndex = (parseInt(pageIndex) + 1).toString();
      // localStorage.setItem("bundlePageIndex", pageIndex);
      // this.callBundleFlowService(pageIndex);
      this.handleTabScroll(1, "bundlePageIndex");
      var pageIndex = localStorage.getItem("bundlePageIndex")
      this.callBundleFlowService(parseInt(pageIndex));
    }

    if (this.activeTabIndex == 2) {
      // var pageIndex = localStorage.getItem("draftPageIndex");
      // pageIndex = (parseInt(pageIndex) + 1).toString();
      // localStorage.setItem("draftPageIndex", pageIndex);
      // this.callDraftFlowService(pageIndex);
      this.handleTabScroll(2, "draftPageIndex");
      var pageIndex = localStorage.getItem("draftPageIndex")
      this.callDraftFlowService(pageIndex);
    }

    if (this.activeTabIndex == 3) {
      // var pageIndex = localStorage.getItem("savePageIndex");
      // pageIndex = (parseInt(pageIndex) + 1).toString();
      // localStorage.setItem("savePageIndex", pageIndex);
      // this.callSaveFlowService(pageIndex);
      this.handleTabScroll(3, "savePageIndex");
      var pageIndex = localStorage.getItem("savePageIndex")
      this.callSaveFlowService(pageIndex);
    }

    if (this.activeTabIndex == 4) {
      // var pageIndex = localStorage.getItem("purchasePageIndex");
      // pageIndex = (parseInt(pageIndex) + 1).toString();
      // localStorage.setItem("purchasePageIndex", pageIndex);
      // this.callPurchasedFlowService(pageIndex);
      this.handleTabScroll(4, "purchasePageIndex");
      var pageIndex = localStorage.getItem("purchasePageIndex")
      this.callPurchasedFlowService(pageIndex);
    }
  }

  private scrollSubject = new Subject<MouseEvent>();
  handleTabScroll(tabIndex, pageIndexKey) {
    if (this.activeTabIndex === tabIndex) {
      var pageIndex = localStorage.getItem(pageIndexKey);
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem(pageIndexKey, pageIndex);
    }
  }

  onScrollOn(event: MouseEvent) {
    this.scrollSubject.next(event);
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.istempFlows = [];
    this.itistempFlows = [];
    if (tabChangeEvent.index === 0) {
      this.activeTabIndex = 0;
      this.getApprovedFlows();
    }
    if (tabChangeEvent.index === 1) {
      this.activeTabIndex = 1;
      this.getBundles(false);
    }
    if (tabChangeEvent.index === 2) {
      this.activeTabIndex = 2;
      this.getDraftFlows(false);
    }
    if (tabChangeEvent.index === 3) {
      this.activeTabIndex = 3;
      this.getSavedFlows(false);
    }
    if (tabChangeEvent.index === 4) {
      this.activeTabIndex = 4;
      this.getPurchasedFlows(false);
    }
  }

  viewUserProfile(selectedFlow) {
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedFlow.customerEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  showImage(itemCategory, item, index) {
    itemCategory.flowModels.forEach((element) => {
      if (element.arrow !== undefined) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.Url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }

  getFlows() {
    this.approvedFlows = [];
    this.flows = [];
    this.getApprovedFlows();
  }

  draft() {
    this.draftFlows = [];
    this.itistempFlows = [];
    this.flows = [];
    this.getDraftFlows(false);
  }

  saved() {
    this.savedFlows = [];
    this.flows = [];
    this.getSavedFlows(false);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize(event);
  }

  resize(event) {
    if (event.srcElement.outerWidth <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  onResize(event) {
    this.resize(event);
  }

  callServiceForApprovedFlows(pageIndex) {
    this.noResultReturned = true;

    const flowQueryModel = this.flowQueryService.createFlowQueryModel(
      '',
      '',
      '',
      pageIndex,
      '',
      ''
    );

    this.flowServiceService
      .getApprovedFlows(flowQueryModel)
      .subscribe((data) => {
        //   
        this.noResultReturned = false;

        if (data.flows.length > 0) {
          this.isthereAnyFlows = true;

          data.flows.forEach((element) => {
            this.tempFlows.push(element);
          });
          this.approvedFlowslength = this.tempFlows.length;
          this.approvedFlows = [];

          this.flows = this.tempFlows;
        } else if (this.tempFlows.length == 0) {
          this.isthereAnyFlows = false;

          var flow = {
            Title: "You don't have any saved flows",
          };
          this.approvedFlows.push(flow);
          this.noApprovedFlows = false;
        }
      });
  }

  getProfileUser(): void {

    this.showNotificationCount = true;
    this.userserviceService
      .getProfileUser()
      .subscribe((data) => {

        if (
          data.userData.profilePicUrl === "" ||
          data.userData.profilePicUrl == null
        ) {
          this.showDefaultImage = true;
        } else {
          this.showDefaultImage = false;
          this.profilePicture = data.userData.profilePicUrl;
        }
        this.email = data.userData.emailAddress;
        this.phone = data.userData.phoneNumber;
        this.info = data.userData.info;
        this.website = data.userData.website;
        this.userDisplayName = this.uname = data.userData.userName;
        this.name = data.userData.firstName;

        this.ShowAboutYou = data.userData.showAboutYou;
        this.ShowEmail = data.userData.showEmail;
        this.ShowName = data.userData.showName;
        this.ShowWebsite = data.userData.showWebsite;
        this.ShowPhone = data.userData.showPhone;

        this.FlowCount = data.userData.flowCount;
        this.Bundlecount = data.userData.bundlecount;
        this.Draftcount = data.userData.draftcount;
        this.Savedcount = data.userData.savedcount;
        this.Purchasedcount = data.userData.purchasedcount;
        this.FollowingCount = data.userData.followingCount;
        this.FollowersCount = data.userData.followerCount;

        if (this.Purchasedcount == null) {
          this.Purchasedcount = 0;
        }
        if (this.FlowCount < 0) {
          var flow = {
            Title: "You don't have any saved flows",
          };
          this.approvedFlows.push(flow);
        }
      });
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      localStorage.setItem("navigatebackfromcreateflow", this.location.path());
      localStorage.setItem(
        "navigatebackfromcreatebundle",
        this.location.path()
      );
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
    this.getnotification();
  }

  callDraftFlowService(pageIndex) {
    this.noResultReturned = true;

    const flowQueryModel = this.flowQueryService.createFlowQueryModel(
      '',
      '',
      this.userData.emailAddress,
      pageIndex,
      '',
      ''
    );

    this.flowServiceService
      .getDraftFlows(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        // 
        if (data.flows.length > 0) {
          this.isthereAnyDraftFlows = true;
          data.flows.forEach((element) => {
            this.itistempFlows.push(element);
          });
          this.draftFlows = this.itistempFlows;
        } else if (this.itistempFlows.length == 0) {
          this.isthereAnyDraftFlows = false;

          var flow = {
            Title: "You don't have any saved flows",
          };
          this.draftFlows.push(flow);
          this.noDraftFlows = false;
        }
      });
  }

  noflow: boolean;

  callPurchasedFlowService(pageIndex) {
    this.noResultReturned = true;

    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: pageIndex
    });
    this.flowServiceService
      .getPurchasedFlows(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        if (data.flows.length > 0) {
          this.isthereAnyPurchasedFlows = true;
          data.flows.forEach((element) => {
            if (element.price) {
              this.istempFlows.push(element);
            }
          });
          this.purchasedFlows = this.istempFlows;
        } else if (this.istempFlows.length == 0) {
          this.isthereAnyPurchasedFlows = false;
          var flow = {
            Title: "You don't have any saved flows",
          };
          this.purchasedFlows.push(flow);
          this.noPurchasedFlows = false;
        }
      });
  }

  callSaveFlowService(pageIndex) {
    this.noResultReturned = true;

    const flowQueryModel = this.flowQueryService.createFlowQueryModel(
      '',
      '',
      this.userData.emailAddress,
      pageIndex,
      '',
      ''
    );

    this.flowServiceService
      .getSavedFlows(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        if (data.flows.length > 0) {
          this.isthereAnySavedFlows = true;
          data.flows.forEach((element) => {
            this.istempFlows.push(element);
          });
          this.savedFlows = this.istempFlows;
        } else if (this.istempFlows.length == 0) {
          this.isthereAnySavedFlows = false;
          var flow = {
            Title: "You don't have any saved flows",
          };
          this.savedFlows.push(flow);
          this.noSavedFlows = false;
        }
      });
  }

  callBundleFlowService(pageIndex: number) {
    this.noResultReturned = true;
    this.noBundles = true;

    const flowQueryModel = this.flowQueryService.createFlowQueryModel(
      '',
      '',
      this.userData.emailAddress,
      pageIndex,
      '',
      ''
    );


    this.flowServiceService
      .getUserBundles(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        if (data.bundles.length > 0) {
          this.isthereAnyBundles = true;
          data.bundles.forEach((element) => {
            this.tempBundles.push(element);
          });
        } else if (data.bundles.length <= 0 && this.tempBundles.length == 0) {
          this.isthereAnyBundles = false;
          var flow = {
            Title: "You don't have any bundles",
          };
          this.tempBundles.push(flow);
        }
      });
  }

  getnotification() {
    this.notificationsarray = [];
    this.flowServiceService
      .getNotifications(this.userData.EmailAddress)
      .subscribe((data) => {
        if (data.userNotifications != null) {
          data.userNotifications.forEach((element) => {
            var notification = {
              notification: {
                ProfilePicUrl: element.profilePicUrl,
                NotifierUserName: element.notifierUserName,
                Message: element.message,
                FlowUrl: element.flowUrl,
                FlowId: element.contentId,
                ContentType: element.contentType,
                NotifierUserEmail: element.notifierUserEmail,
                VideoFlowPosterImageUrl: element.videoFlowPosterImageUrl,
              },
            };
            this.notificationsarray.push(notification);
          });
        }
      });
  }

  followersFollowing(id) {
    localStorage.setItem("tabIndex", id);
    this.router.navigate(["/followers"], id);
  }

  openDialog() {
    if (this.userData != null) {
      this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
      this.add_circle_outlinebgColor = "white";
      this.add_circle_outlineColor = "#5ea1d8 !important";
      this.add_circle_outlineSize = "25px !important";
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {
       
    this.eventEmitterService.setUserProfileCreateIconClickedEvent("profile");
        if (result === "bundle") {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
          this.router.navigate(["/createbundle"]);
        } else if (result === "flow") {
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
          this.router.navigate(["/create-flow"]);
        } else{
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("profile");
          this.add_circle_outlinebgColor = "white";
          this.add_circle_outlineColor = "#212427 !important";
        }
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
