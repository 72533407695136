// Enums

import { EnvName } from "src/app/shared/environment.enum";
// Packages
// import packageInfo from '../../package.json';

const scheme = 'https://';
const host = 'flowchestapi.azurewebsites.net';
//const host   = 'localhost:7116';
const path = '/';

const baseUrl = scheme + host + path;


//const baseUrl = scheme + host + path;

export const environment = {
  production: true,
  appName: 'Flowchest',
  envName: EnvName.PROD,
  defaultLanguage: 'en',
   webBaseUrl: "https://flowchest.com",
  apiBaseUrl: baseUrl,
  environmentKey: '2517029690634020193',
  applicationKey: '2517029690757091351',
  // uploadImageUrl: 'https://swashcmsapi.azurewebsites.net/api/media/upload/image',
  // uploadVideoUrl: 'https://swashcmsapi.azurewebsites.net/api/media/upload/video',
  // imageStorageBaseUrl: 'https://seasycmstest.blob.core.windows.net/mediaimage/',
  // videoStorageBaseUrl: 'https://seasycmstest.blob.core.windows.net/mediavideo/'

  uploadImageUrl: 'api/media/upload/image',
  uploadVideoUrl: 'api/media/upload/video',
  uploadDocumentsUrl:'api/media/upload/documents',
  imageStorageBaseUrl: 'https://flowcheststorage.blob.core.windows.net/mediaimage/',
  videoStorageBaseUrl: 'https://flowcheststorage.blob.core.windows.net/mediavideo/',
  documentsStorageBaseUrl: 'https://flowcheststorage.blob.core.windows.net/documents/'
  
};
