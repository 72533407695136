import { ShowArticleDialogComponent } from "./pages/show-article-dialog/show-article-dialog.component";
import { ThirdpartyliabrariesComponent } from "./pages/thirdpartyliabraries/thirdpartyliabraries.component";
import { BundleqrcodeComponent } from "./pages/bundleqrcode/bundleqrcode.component";
import { ReportBundleComponent } from "./pages/report-bundle/report-bundle.component";
import { DeleteBundleDialogComponent } from "./pages/delete-bundle-dialog/delete-bundle-dialog.component";
import { EditBundleComponent } from "./pages/edit-bundle/edit-bundle.component";
import { RestrictedforbundleComponent } from "./pages/restrictedforbundle/restrictedforbundle.component";
import { ViewBundleComponent } from "./pages/view-bundle/view-bundle.component";
import { AddflowsinBundleComponent } from "./pages/addflowsin-bundle/addflowsin-bundle.component";
import { CreatebundleComponent } from "./pages/createbundle/createbundle.component";
import { EditFlowComponent } from "./pages/edit-flow/edit-flow.component";
import { RestrictedAccessComponent } from "./pages/restricted-access/restricted-access.component";
import { ImageDialogComponent } from "./pages/image-dialog/image-dialog.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { CaptureImageComponent } from "./pages/capture-image/capture-image.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { SampleComponent } from "./pages/sample/sample.component";
import { EmployeeComponent } from "./pages/employee/employee.component";
import { LoginComponent } from "./pages/login/login.component";
import { ViewflowComponent } from "./pages/viewflow/viewflow.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { TermsandconditionsComponent } from "./termsandconditions/termsandconditions.component";
import { ViewbyidComponent } from "./pages/viewbyid/viewbyid.component";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { ViewByIdResolver } from "./pages/viewbyid/viewbyid.resolver";
import { FlowByCategoryComponent } from "./pages/flow-by-category/flow-by-category.component";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { PreviewPageComponent } from "./pages/preview-page/preview-page.component";
import { ForgetPasswordComponent } from "./pages/forget-password/forget-password.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ViewProfileComponent } from "./pages/view-profile/view-profile.component";
import { QRCodeComponent } from "./pages/qrcode/qrcode.component";
import { CommentComponent } from "./pages/comment/comment.component";
import { EditCommentComponent } from "./pages/edit-comment/edit-comment.component";
import { ChangeOldPasswordComponent } from "./pages/change-old-password/change-old-password.component";
import { CreateFlowComponent } from "./pages/create-flow/create-flow.component";
import { CoverPageComponent } from "./pages/cover-page/cover-page.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { ItemsComponent } from "./pages/items/items.component";
import { StepsComponent } from "./pages/steps/steps.component";
import { NotificationsComponent } from "./pages/notifications/notifications.component";
import { SearchComponent } from "./pages/search/search.component";
import { BulbGuideComponent } from "./pages/bulb-guide/bulb-guide.component";
import { InAppropriateComponent } from "./pages/in-appropriate/in-appropriate.component";
import { FollowersComponent } from "./pages/followers/followers.component";
import { FollowingComponent } from "./pages/following/following.component";
import { UserProfileQrCodeComponent } from "./pages/user-profile-qr-code/user-profile-qr-code.component";
import { ActivateaccountComponent } from "./pages/activateaccount/activateaccount.component";
import { ArticleComponent } from "./pages/article/article.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { VouchercodeComponent } from "./pages/vouchercode/vouchercode.component";
import { UserDashboardComponent } from "./pages/user-dashboard/user-dashboard.component";
import { AdminDashboardComponent } from "./pages/admin-dashboard/admin-dashboard.component";
import { EmbededComponent } from "./pages/embeded/embeded.component";
import { EmbedforbundlesComponent } from "./pages/embedforbundles/embedforbundles.component";
import { EditItemComponent } from "./pages/edit-item/edit-item.component";
import { AnalyticsComponent } from "./pages/analyics/analytics.component";
import { CouponCodeComponent } from "./pages/coupon-code/coupon-code.component";
import { ContentAnalyticsComponent } from "./pages/content-analytics/content-analytics.component";
import { PricingplanComponent } from "./pages/pricingplan/pricingplan.component"; 
import { PreviewBlogComponent } from "./pages/blogs/blog/blog.component";
import { BlogsComponent } from "./pages/blogs/blogs.component";
import { DeleteUserDialogComponent } from "./pages/delete-user-dialog/delete-user-dialog.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 464],
};

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },{
    path: "pricing",
    component: PricingplanComponent,
  },{
    path: 'blog/:title',
    component: PreviewBlogComponent,
  },
  {
    path: "registration",
    component: RegistrationComponent,
  },
  {
    path: "grid",
    component: SearchComponent,
  },
  {
    path: "activateaccount",
    component: ActivateaccountComponent,
  },
  {
    path: "user-dashboard",
    component: UserDashboardComponent,
  },
  {
    path: "admin-dashboard",
    component: AdminDashboardComponent,
  },
  {
    path: "privacy",
    component: PrivacypolicyComponent,
  },
  {
    path: "tandc",
    component: TermsandconditionsComponent,
  },
  {
    path: "viewbyid:/id",
    component: ViewflowComponent,
    resolve: { resolvedata: ViewByIdResolver },
  },
  {
    path: "detail/:id",
    component: ViewbyidComponent,
    resolve: {
      resolvedata: ViewByIdResolver,
    },
  },
  {
    path: "view",
    component: ViewflowComponent,
  },
  {
    path: "view/:rowKey",
    component: ViewflowComponent,
  },
  {
    path: "flowbycategory",
    component: FlowByCategoryComponent,
  },
  {
    path: "notifications",
    component: NotificationsComponent,
  },
  {
    path: "user-profile",
    component: UserProfileComponent,
  },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "preview-page",
    component: PreviewPageComponent,
  },
  {
    path: "forget-password",
    component: ForgetPasswordComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
  },
  {
    path: "change-old-password",
    component: ChangeOldPasswordComponent,
  },
  {
    path: "view-profile",
    component: ViewProfileComponent,
  },
  {
    path: "view-profile/:uname",
    component: ViewProfileComponent,
  },
  {
    path: "qrcode",
    component: QRCodeComponent,
  },
  {
    path: "comment",
    component: CommentComponent,
  },
  {
    path: "edit-comment",
    component: EditCommentComponent,
  },
  {
    path: "create-flow",
    component: CreateFlowComponent,
  },
  {
    path: "cover-page",
    component: CoverPageComponent,
  },
  {
    path: "categories",
    component: CategoriesComponent,
  },
  {
    path: "in-Appropriate",
    component: InAppropriateComponent,
  },

  {
    path: "items",
    component: ItemsComponent,
  },
  {
    path: "edititem",
    component: EditItemComponent,
  },
  {
    path: "couponcode",
    component: CouponCodeComponent,
  },
  {
    path: "analytics",
    component: AnalyticsComponent,
  },
  {
    path:"content-analytics",
    component:ContentAnalyticsComponent,
  },
  {
    path: "capture-image",
    component: CaptureImageComponent,
  },
  {
    path: "steps",
    component: StepsComponent,
  },
  {
    path: "home",
    component: SearchComponent,
  },
  {
    path: "privacypolicy",
    component: PrivacypolicyComponent,
  },
  {
    path: "termsandconditions",
    component: TermsandconditionsComponent,
  },
  {
    path: "bulbguide",
    component: BlogsComponent,
  },
  {
    path: "aboutus",
    component: AboutUsComponent,
  },
  {
    path: "imageDialog",
    component: ImageDialogComponent,
  },
  {
    path: "followers",
    component: FollowersComponent,
  },
  {
    path: "following",
    component: FollowingComponent,
  },
  {
    path: "restrictedPage",
    component: RestrictedAccessComponent,
  },
  {
    path: "editFlow",
    component: EditFlowComponent,
  },
  {
    path: "createbundle",
    component: CreatebundleComponent,
  },
  {
    path: "viewbundle",
    component: ViewBundleComponent,
  },
  {
    path: "editbundle/:id",
    component: EditBundleComponent,
  },
  {
    path: "viewbundle/:id",
    component: ViewBundleComponent,
  },
  {
    path: "userQrCode",
    component: UserProfileQrCodeComponent,
  },
  {
    path: "restrictedbundle",
    component: RestrictedforbundleComponent,
  },
  {
    path: "deleteBundleDialog",
    component: DeleteBundleDialogComponent,
  },
  {
    path: "deleteUserDialog",
    component: DeleteUserDialogComponent,
  },
  {
    path: "addFlowBundle",
    component: AddflowsinBundleComponent,
  },
  {
    path: "reportBundle",
    component: ReportBundleComponent,
  },
  {
    path: "thirdparty",
    component: ThirdpartyliabrariesComponent,
  },
  {
    path: "article",
    component: ArticleComponent,
  },
  {
    path: "bundleqrcode",
    component: BundleqrcodeComponent,
  },
  {
    path: "showArticle",
    component: ShowArticleDialogComponent,
  },
  {
    path: "vouchercode",
    component: VouchercodeComponent,
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "home",
  },
  {
    path: "checkout",
    component: CheckoutComponent,
  },
  {
    path: "embeded",
    component: EmbededComponent,
  },
  {
    path: "embedforbundles",
    component: EmbedforbundlesComponent,
  },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
